<template>
  <div class="d-flex">
    <b-button style="width: 6rem;border-radius: 0;" variant="primary" @click="$router.push({ name: 'welcome.index' })">
      <feather-icon icon="ChevronLeftIcon" class="font-large-1" />
    </b-button>
    <b-button style="width: 6rem;border-radius: 0;" variant="warning" @click="$router.push({ name: 'cart.index' })">
      <feather-icon icon="ShoppingCartIcon" class="font-large-1" />
    </b-button>
    <div id="tour-search" class="search__box pl-2 border-bottom" :class="className">
      <div class="search__icon">
        <feather-icon class="fw-bold" size="25" icon="SearchIcon" />
      </div>
      <div class="search__text">
        <b-form-input id="search-product" v-model="filter.search" class="focus-0" type="text" placeholder="Cari Produk"
          @focus="className = 'bg-white'" @blur="onBlur" />
      </div>
      <div v-if="filter.search" class="align-self-center p-1" role="button"
        @click="(filter.search = ''), (className = '')" id="button--clear__filter">
        <feather-icon size="18" icon="XIcon" />
      </div>
    </div>
    <div v-if="filter.search" class="search__item p-2" style="overflow-y: scroll" :class="!isLoading && result && result.data && result.data.length == 0
      ? 'd-flex align-items-center justify-content-center'
      : 'd-block'
      ">
      <h6 v-if="is_add_child" class="text-dark text-darken-7 fw-bold-700 size14 mb-1">
        Template Produk dari Toqoo
      </h6>
      <div v-if="isLoading" class="d-flex justify-content-center mb-1 mt-5">
        <b-spinner label="Loading..." />
      </div>
      <div v-if="!isLoading">
        <b-card v-for="(item, index) in result.data" :key="index" no-body class="mb-1 border-12">
          <b-card-body class="p-2">
            <div class="d-flex">
              <div class="image__container">
                <b-img :src="item.image_url || ''" width="47" height="47" alt="image" />
              </div>
              <div class="product__information w-100 d-flex justify-content-between">
                <div class="d-flex flex-column w-100">
                  <div class="d-flex justify-content-between mb-1">
                    <h1 class="h5 text-dark fw-bold-800 mb-0 size14">
                      {{ item.alias_name || "" }}
                    </h1>
                    <h1 class="h5 text-dark font-weight-bolder mb-0">
                      {{ item.standard_price | formatAmount }}
                    </h1>
                  </div>
                  <div class="d-flex justify-content-between">
                    <small class="fw-bold-500 text-dark text-darken-4">{{ item.stock || 0 }} {{ item.unit || ""
                      }}</small>
                    <b-button :disabled="item.is_on_stock_opname" size="sm" variant="secondary" @click.stop="
                      item.is_default == true
                        ? detailProductParent(item)
                        : detailProduct(item.uuid)
                      " id="button--detail__product">
                      <!-- detailProduct(products.uuid) -->
                      <b-spinner v-if="isLoadingEdit && item.uuid === id" small variant="primary" class="mr-1" />
                      <feather-icon v-else icon="ShoppingCartIcon" class="mr-1" />
                      {{
                        !item.is_on_stock_opname
                          ? "Tambahkan"
                          : "Sedang Stok Opname"
                      }}
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
        <div class="d-flex align-items-center justify-content-center" v-if="
          result &&
          result.data &&
          result.data.length > 0 &&
          result.next_page_url != null
        ">
          <b-spinner v-if="isLoadingLoadMore" class="me-2" label="Loading..." />
          <b-button @click="loadMoreProduct" id="button--load__more">
            Load More
          </b-button>
        </div>
      </div>
      <div v-if="!isLoading && result && result.data && result.data.length == 0">
        <div class="d-flex justify-content-center align-items-center">
          <div class="text-center">
            <h6 class="h5 text-dark fw-bold-800 size14 mt-2">
              <!-- Pilih barang untuk dimasukan keranjang -->
              Oops!
            </h6>
            <h6 class="text-dark fw-bold-800 size14">
              Hasil tidak ditemukan.
            </h6>
            <!-- <b-button @click="filter.page = 1, $bvModal.show('modal-add-product')" v-if="checkPermission('add produk')">
              Tambah Produk
            </b-button> -->
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-add-product" hide-header hide-footer centered title="Vertically Centered" ok-only
      ok-title="Accept">
      <div class="p-2 d-flex justify-content-center text-center">
        <h4 class="text-dark font-bold-700 size16 mb-0">Tambah Produk</h4>
      </div>
      <b-container class="mb-md-3 mb-2">
        <b-row class="p-2">
          <b-col cols="12">
            <div class="mb-2 custom__form--input" v-if="!resultDetail.is_default">
              <label>Nama Produk <span class="text-danger">*</span></label>
              <b-form-input id="search-product" v-model="formPayloadCheck.alias_name" class="focus-0 custom__input"
                type="text" placeholder="Nama Produk" />
            </div>
            <div class="mb-2" v-if="!resultDetail.is_default">
              <label for="v-satuan" class="text-dark fw-bold-600 size14">Satuan <span
                  class="text-danger">*</span></label>
              <v-select v-model="formPayloadCheck.uom_uuid" placeholder="Pilih Satuan" label="name" taggable
                :options="optionUom" @search="onSearchUom" />
            </div>
            <div class="mb-2 custom__form--input">
              <label for="harga-beli">Harga Jual Standard <span class="text-danger">*</span></label>
              <input id="harga-jual-standard" class="focus-0 custom__input pl-1 w-100" type="text"
                v-model="formPayloadCheck.standard_price" @input="getRupiahStandard" @change="getRupiahStandard" />
            </div>
            <div class="mb-2 custom__form--input">
              <label for="harga-beli">Harga Jual Minimum <span class="text-danger">*</span></label>
              <input id="harga-jual-minimum" class="focus-0 custom__input pl-1 w-100" type="text"
                v-model="formPayloadCheck.minimum_price" @input="getRupiahMinimum" @change="getRupiahMinimum" />
            </div>
            <div class="mb-2 custom__form--input">
              <label for="harga-beli">Harga Beli <span class="text-danger">*</span></label>
              <input id="harga-beli" class="focus-0 custom__input pl-1 w-100" type="text"
                v-model="formPayloadCheck.buying_price" @input="getRupiah" @change="getRupiah" />
            </div>
            <div class="mb-2 custom__form--input">
              <label class="mb-1">
                Pilih Lokasi SKU <span class="text-danger">*</span>
              </label>
              <div v-for="(warehouse, index) in warehouses" :key="index">
                <div class="d-flex justify-content-between mb-1">
                  <h6 class="text-dark fw-bold-700">
                    {{ warehouse.name }}
                  </h6>
                  <b-form-checkbox :id="`yes-${index}`" :ref="`warehouseId${index}`" v-model="formPayloadCheck.initial_stocks[index].warehouse_uuid
                    " :value="warehouse.uuid" class="custom-control-success">
                    Ya
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div class="button__group">
        <b-button class="button__cancel" @click="$bvModal.hide('modal-add-product')" id="button--hide__product">
          Batal
        </b-button>
        <b-button class="button__save d-flex align-items-center justify-content-center" :disabled="resultDetail.is_default
          ? formPayloadCheck.initial_stocks.every(
            (e) => e.warehouse_uuid == ''
          ) ||
          isLoadingProduct ||
          formPayloadCheck.standard_price == '' ||
          formPayloadCheck.buying_price == '' ||
          formPayloadCheck.minimum_price == ''
          : formPayloadCheck.initial_stocks.every(
            (e) => e.warehouse_uuid == ''
          ) &&
          (formPayloadCheck.alias_name == '' ||
            formPayloadCheck.uom_uuid == '' ||
            formPayloadCheck.standard_price == '' ||
            formPayloadCheck.buying_price == '' ||
            formPayloadCheck.minimum_price == '')
          " @click.prevent="createItem" id="button--save__product">
          <div v-if="isLoadingProduct">
            <b-spinner small variant="primary" />
          </div>
          Simpan
        </b-button>
      </div>
    </b-modal>
    <ModalEditProduct v-if="filter.search" :key="1" :modal-key="'search'" :result="resultDetail"
      :form-data="formPayload" :create-item-to-cart="createItemToCart" :messages="messages" :loading="isLoading"
      :is-default="isDefault" @typeWarehouse="getTypeWarehouse" @payload="getPayload" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import {
  BFormInput,
  BImg,
  BCard,
  BCardBody,
  BSpinner,
  BButton,
  BModal,
  BContainer,
  BFormCheckbox,
  BRow,
  BCol,
} from "bootstrap-vue";
import {
  checkPermission,
  successNotification,
  errorNotification,
  preformatFloat,
} from "@/auth/utils";
import ModalEditProduct from "@/components/Cashier/Modal/AddProductToCart.vue";
import vSelect from "vue-select";

export default {
  components: {
    BFormInput,
    BImg,
    BCard,
    BCardBody,
    BSpinner,
    ModalEditProduct,
    BButton,
    BModal,
    BContainer,
    BFormCheckbox,
    BRow,
    BCol,
    vSelect,
  },
  props: {
    is_add: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
      preformatFloat,
    };
  },
  data() {
    return {
      totalBuy: this.$store.state.cashier.totalBuy,
      isLoading: false,
      isLoadingEdit: false,
      className: "",
      is_add_child: false,
      result: {},
      filter: {
        search: "",
        per_page: 10,
      },
      formPayload: {
        delivery_numbers_uuid: [],
        po_so_uuid: "",
        product_uuid: "",
        warehouse_uuid: "",
        qty: 0,
        price: 0,
        discounts: [],
        is_bonus: 0,
      },
      resultDetail: {},
      messages: "",
      id: "",
      typeWarehouse: "",
      isDefault: false,
      warehouses: [],
      formPayloadCheck: {
        product_type: "parent",
        alias_name: "",
        uom_uuid: "",
        buying_price: "",
        minimum_price: "",
        standard_price: "",
        initial_stocks: [
          {
            warehouse_uuid: "",
          },
        ],
      },
      next_page_url: "",
      optionUom: [],
      isLoadingProduct: false,
      isLoadingLoadMore: false,
      page: 2,
    };
  },
  watch: {
    "formPayload.uom_uuid": function (value) {
      if (value?.label) {
        this.getUomDetail(value.label);
        // this.formPayload.uom_uuid = value.label
      }
    },
    // 'formPayloadCheck.uom_uuid': function (value) {
    //   if (value.label) {
    //     this.formPayloadCheck.uom_uuid = value
    //     console.log(this.formPayloadCheck.uom_uuid)
    //     // this.formPayload.uom_uuid = value.label
    //   }
    // },
    "$store.state.cashier.totalBuy": function (value) {
      const vm = this;
      vm.totalBuy = value;
    },
    is_add(value) {
      this.is_add_child = value;
    },
    is_add_child(value) {
      this.$emit("getIsAdd", value);
    },
    filter: {
      handler: _.debounce(function () {
        this.searchProduct();
      }, 300),
      deep: true,
    },
  },
  mounted() {
    this.getWarehouse();
    this.getUom();
  },
  methods: {
    getRupiahStandard() {
      const rupiah = document.getElementById("harga-jual-standard");
      rupiah.value = this.formatRupiah(rupiah.value, "");
      this.formPayloadCheck.standard_price = rupiah.value;
    },
    getRupiahMinimum() {
      const rupiah = document.getElementById("harga-jual-minimum");
      rupiah.value = this.formatRupiah(rupiah.value, "");
      this.formPayloadCheck.minimum_price = rupiah.value;
    },
    getRupiah() {
      const rupiah = document.getElementById("harga-beli");
      rupiah.value = this.formatRupiah(rupiah.value, "");
      this.formPayloadCheck.buying_price = rupiah.value;
    },
    formatRupiah(angka, prefix) {
      const number_string = angka.replace(/[^,\d]/g, "").toString();
      const split = number_string.split(",");
      const sisa = split[0].length % 3;
      let rupiah = split[0].substr(0, sisa);
      const ribuan = split[0].substr(sisa).match(/\d{3}/gi);

      if (ribuan) {
        const separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      rupiah = split[1] != undefined ? `${rupiah},${split[1]}` : rupiah;
      return prefix == undefined ? rupiah : rupiah || "";
    },
    async getUomDetail(id) {
      await this.$store
        .dispatch("uom/getData", {
          uuid: id,
          params: "",
        })
        .then((result) => {
          setTimeout(() => {
            this.optionUom.push({
              label: result.data.data.uuid,
              name: result.data.data.name,
            });
            const option = this.getUniqueListBy(this.optionUom, "label");
            this.optionUom = option;
          }, 500);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    onSearchUom(search, loading) {
      if (search.length) {
        loading(true);
        this.searchUom(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.optionUom = [];
          this.getUom();
          loading(false);
        }, 500);
      }
    },
    searchUom: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("uom/getData", {
          uuid: "",
          params: {
            search: escape(search),
          },
        })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          vm.optionUom = [];
          response.data.data.data.forEach((item) => {
            vm.optionUom.push({
              label: item.uuid,
              name: item.name,
            });
          });
          loading(false);
        });
    }, 300),
    async getUom() {
      this.$store
        .dispatch("uom/getData", {
          uuid: "",
          params: "",
        })
        .then((result) => {
          result.data.data.data.forEach((element) => {
            this.optionUom.push({
              label: element.uuid,
              name: element.name,
            });
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    isRadioChecked() {
      // const that = this
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.$refs.warehouseId) {
        if (Object.hasOwnProperty.call(this.$refs.warehouseId, key)) {
          const element = this.$refs.warehouseId[key];
          if (element.isChecked === true) {
            this.formPayloadCheck.warehouse_uuid.push(element.value);
          } else {
            this.removeA(this.formPayloadCheck.warehouse_uuid, element.value);
          }
        }
      }
      const items = [...new Set(this.formPayloadCheck)];
      this.$emit("getWarehouseUuid", items);
      // for (let index = 0; index < this.warehouses.length; index++) {
      // }
    },
    removeA(arr) {
      // eslint-disable-next-line prefer-rest-params
      let what;
      const a = arguments;
      let L = a.length;
      let ax;
      while (L > 1 && arr.length) {
        // eslint-disable-next-line no-plusplus
        what = a[--L];
        // eslint-disable-next-line no-cond-assign
        while ((ax = arr.indexOf(what)) !== -1) {
          arr.splice(ax, 1);
        }
      }
      return arr;
    },
    getWarehouse() {
      this.$store
        .dispatch("warehouse/getData", {
          uuid: "",
          params: "",
        })
        .then((result) => {
          this.warehouses = result.data.data;
          for (let index = 0; index < result.data.data.length; index++) {
            this.formPayloadCheck.initial_stocks.push({
              warehouse_uuid: "",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTypeWarehouse(value) {
      this.typeWarehouse = value;
    },
    getPayload(value) {
      this.formPayload = value;
    },
    onBlur() {
      if (this.filter.search.length === 0) {
        this.className = "";
        this.is_add_child = false;
        this.$store.commit("cart/setKey", 0);
      }
    },
    createItem() {
      if (this.resultDetail.is_default) {
        this.formPayloadCheck.product_uuid = this.resultDetail.uuid;
        this.formPayloadCheck.alias_name = this.resultDetail.alias_name;
        this.formPayloadCheck.uom_uuid = this.resultDetail.unit_uuid;
      }

      const form = this.preparePayloadProduct();
      if (!this.resultDetail.is_default) {
        form.append(
          "uom_uuid",
          this.formPayloadCheck.uom_uuid.label ||
          this.formPayloadCheck.uom_uuid.name ||
          this.formPayloadCheck.uom_uuid
        );
      } else {
        form.append("uom_uuid", this.resultDetail.unit_uuid);
      }

      this.isLoadingProduct = true;
      this.$store
        .dispatch("parentProduct/postData", {
          query: "",
          payload: form,
        })
        .then(async () => {
          await this.searchProduct();
          await this.clearWarehouse();
          successNotification(this, "Success", "Produk berhasil di buat");
          // this.filter.search = ''
          this.formPayloadCheck = {
            ...this.formPayloadCheck,
            product_type: "parent",
            alias_name: "",
            uom_uuid: "",
            standard_price: "",
            minimum_price: "",
            buying_price: "",
          };
          this.isLoadingProduct = false;
          this.$bvModal.hide("modal-add-product");
        })
        .catch(() => {
          this.isLoadingProduct = false;
        });
    },
    clearWarehouse() {
      for (let index = 0; index < this.warehouses.length; index++) {
        this.formPayloadCheck.initial_stocks[index].warehouse_uuid = "";
      }
    },
    async createItemToCart() {
      this.isLoading = true;
      if (this.$store.state.cart.isEdit === false) {
        this.formPayload.product_uuid = this.id;
      }
      if (this.typeWarehouse === "warehouse") {
        delete this.formPayload.po_so_uuid;
        delete this.formPayload.delivery_numbers_uuid;
      }
      if (!this.typeWarehouse) {
        delete this.formPayload.po_so_uuid;
        delete this.formPayload.delivery_numbers_uuid;
      }
      if (this.isDefault === true) {
        delete this.formPayload.po_so_uuid;
        delete this.formPayload.delivery_numbers_uuid;
      }
      const form = this.preparePayload();
      await this.$store
        .dispatch(
          `cart/${this.$store.state.cart.isEdit === true
            ? "postEditItemToCart"
            : "postAddItemToCart"
          }`,
          {
            uuid:
              this.$store.state.cart.isEdit === false
                ? this.$store.state.cart.cartUuid
                : this.$store.state.cart.itemUuid,
            payload: form,
          }
        )
        .then(() => {
          successNotification(
            this,
            "Success",
            `Produk berhasil ${this.$store.state.cart.isEdit === false ? "ditambahkan" : "diubah"
            } ke cart`
          );
          this.getActiveCart();
          this.isLoading = false;
          this.formPayload = {};
          this.$bvModal.hide("modal-add-product-to-cart-search");
          this.$store.commit("cart/setIsEdit", false);
          this.filter.search = "";
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          this.isLoading = false;
          this.messages = err.response.data.meta.messages;
        });
    },
    async searchProduct() {
      this.isLoading = true;
      const queryParams = this.filter;
      queryParams.page = 1;
      queryParams.with_delivery_numbers = 1;

      await this.$store
        .dispatch("cashier/getData", {
          uuid: "",
          params: queryParams,
        })
        .then((result) => {
          this.result = result.data.data;
          this.isLoading = false;
          // eslint-disable-next-line no-console
        })
        .catch((err) => {
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async loadMoreProduct() {
      this.isLoadingLoadMore = true;
      const queryParams = this.filter;
      queryParams.with_delivery_numbers = 1;
      // eslint-disable-next-line no-plusplus
      queryParams.page = this.page++;

      await this.$store
        .dispatch("cashier/getData", {
          uuid: "",
          params: queryParams,
        })
        .then((result) => {
          this.result.next_page_url = result.data.data.next_page_url;
          // Array.prototype.push.apply(this.result, result.data.data)
          this.result.data = [...this.result.data, ...result.data.data.data];
          this.isLoadingLoadMore = false;
          // eslint-disable-next-line no-console
        })
        .catch((err) => {
          this.isLoadingLoadMore = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    detailProductParent(item) {
      this.isDefault = true;
      this.resultDetail = item;
      this.formPayload = {
        ...this.formPayload,
        discounts: [],
        is_bonus: 0,
        price: "0",
        qty: "0",
        warehouse_uuid: "",
      };
      this.id = item.uuid;
      this.$bvModal.show("modal-add-product-to-cart-search");
    },
    async detailProduct(id) {
      this.isDefault = false;
      this.isLoadingEdit = true;
      this.$store.commit("cart/setIsEdit", false);
      this.formPayload.product_uuid = id;
      this.id = id;
      await this.$store
        .dispatch("cashier/getData", {
          uuid: `/${id}`,
          params: {
            with_delivery_numbers: 1,
          },
        })
        .then((result) => {
          const item = result.data.data;
          this.resultDetail = item;
          this.formPayload = {
            is_bonus: 0,
            price: item.standard_price || "0",
            discounts: item.discounts || [],
          };
          this.isLoadingEdit = false;
          this.$bvModal.show("modal-add-product-to-cart-search");
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    async getActiveCart() {
      await this.$store
        .dispatch("cart/getCart", {
          uuid: this.$route.query.uuid || "",
          params: "",
        })
        .then((result) => {
          const item = result.data.data;
          this.$store.commit("cart/setDiscountCart", item.discount_value);
          this.$store.commit("cart/setDiscountType", item.discount_type);
          this.$store.commit("cart/setDiscountTotal", item.discount_total);
          this.$store.commit("cart/setSubtotalCart", item.subtotal);
          this.$store.commit("cart/setTotalPrice", item.total);
          this.$store.commit("cart/setTotalBuy", item.items.length);
          this.$store.commit("cart/setCartUuid", item.uuid);
          this.$store.commit("cart/setResultCartActive", item);
          this.$store.commit("cashier/setProduct", item.items.length);
          localStorage.setItem("cartUuid", item.uuid);
          localStorage.setItem("cash_total_payment", item.total);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    },
    preparePayload() {
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayload.hasOwnProperty(key) &&
          this.formPayload[key] != null
        ) {
          if (
            key !== "price" &&
            key !== "qty" &&
            key !== "discounts" &&
            key !== "delivery_numbers_uuid" &&
            key !== "warehouse_uuid" &&
            key !== "supplier"
          ) {
            form.append(key, this.formPayload[key]);
          }
          if (key === "price" || key === "qty") {
            // eslint-disable-next-line radix
            form.append(
              key,
              parseFloat(this.preformatFloat(this.formPayload[key].toString()))
            );
          }
        }
      }
      if (this.typeWarehouse === "warehouse") {
        form.append("warehouse_uuid", this.formPayload.warehouse_uuid);
      }
      if (this.typeWarehouse === "supplier") {
        form.append("supplier_uuid", this.formPayload.warehouse_uuid);
      }
      if (this.formPayload.discounts.length) {
        for (
          let index = 0;
          index < this.formPayload.discounts.length;
          index++
        ) {
          const element = this.formPayload.discounts[index];
          // eslint-disable-next-line no-restricted-syntax
          for (const key in element) {
            if (Object.hasOwnProperty.call(element, key)) {
              const items = element[key];
              form.append(`discounts[${index}][${key}]`, items);
            }
          }
        }
      }
      if (this.formPayload.delivery_numbers_uuid) {
        for (
          let index = 0;
          index < this.formPayload.delivery_numbers_uuid.length;
          index++
        ) {
          const element = this.formPayload.delivery_numbers_uuid[index];
          form.append("delivery_numbers_uuid[]", element.label);
        }
      }
      return form;
    },
    preparePayloadProduct() {
      const form = new FormData();
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayloadCheck) {
        // eslint-disable-next-line no-prototype-builtins
        if (
          this.formPayloadCheck.hasOwnProperty(key) &&
          this.formPayloadCheck[key] != null
        ) {
          if (
            key !== "initial_stocks" &&
            key !== "buying_price" &&
            key !== "minimum_price" &&
            key !== "standard_price" &&
            key !== "uom_uuid"
          ) {
            form.append(key, this.formPayloadCheck[key]);
          }
          if (
            key === "buying_price" ||
            key === "minimum_price" ||
            key === "standard_price"
          ) {
            form.append(
              key,
              parseFloat(this.preformatFloat(this.formPayloadCheck[key]))
            );
          }
        }
      }
      if (this.formPayloadCheck.initial_stocks) {
        // eslint-disable-next-line no-plusplus
        for (
          let index = 0;
          index < this.formPayloadCheck.initial_stocks.length;
          index++
        ) {
          const element = this.formPayloadCheck.initial_stocks[index];
          if (
            element.warehouse_uuid !== "" &&
            element.warehouse_uuid !== false
          ) {
            // eslint-disable-next-line no-restricted-syntax
            for (const key in element) {
              if (Object.hasOwnProperty.call(element, key)) {
                // eslint-disable-next-line no-use-before-define
                const items = element[key];
                form.append(`initial_stocks[${index}][${key}]`, items);
              }
            }
          }
        }
      }
      return form;
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.search__item {
  position: absolute;
  z-index: 100;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.04);
  height: calc(100vh - 76px);

  .card {
    box-shadow: none;
    border: 1px solid #e4e5ec;
    border-radius: 12px;
  }
}

.image__container {
  margin-right: 1rem;

  img {
    width: 47px;
    height: 47px;
    object-fit: cover;
    border-radius: 8px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";

#modal-add-product {
  .modal-dialog {
    .modal-content {
      background: #fcfcfc;
      border-radius: 16px;

      .modal-body {
        padding: 0;

        .form-group {
          label {
            font-size: 14px;
            color: $dark;
            font-weight: 600;
          }

          input {
            background: #e7e7ed;
            border: none;
            border-radius: 8px;
            height: 52px;
            color: $dark;
            font-weight: 600;
          }
        }

        .button__group {
          display: flex;
          width: 100%;

          .button__cancel {
            background-color: #ffffff !important;
            border-color: #fff !important;
            color: $dark !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 0px 16px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }

          .button__save {
            background-color: #45b6ab !important;
            border-color: #45b6ab !important;
            box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.04);
            border-radius: 0px 0px 16px 0px;
            width: 100%;
            height: 72px;
            font-size: 16px;
            font-weight: 800;
          }
        }
      }
    }
  }
}
</style>
